import Vue from "vue";
import Router from "vue-router";
import Header from "./layout/starter/StarterHeader";
import Footer from "./layout/starter/StarterFooter";
import Starter from "./views/Starter.vue";
import HeadsDown from "./views/HeadsDown.vue";

Vue.use(Router);

var router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "starter",
      components: {
        header: Header,
        default: Starter,
        footer: Footer
      },
      meta: {
        title: 'Home of Hacks | Area42 Labs',
        metaTags: [
          {
            name: 'description',
            content: 'Area42 Labs: Home for useful side projects and hacks.'
          },
          {
            property: 'og:description',
            content: 'Area42 Labs: Home for useful side projects and hacks.'
          }
        ]
      }
    },
    {
      path: "/headsdown",
      name: "headsdown",
      components: {
        header: Header,
        default: HeadsDown,
        footer: Footer
      },
      meta: {
        title: 'HeadsDown - Work, distracton free. | Area42 Labs',
        metaTags: [
          {
            name: 'description',
            content: 'Auto-enable "Do not disturb" while coding or designing.'
          },
          {
            property: 'og:description',
            content: 'Auto-enable "Do not disturb" while coding or designing.'
          }
        ]
      }
    }
  ]
});

// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if(!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router