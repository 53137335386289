<template>
    <div>
        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 bg-gradient-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3 text-white">Heads Down 👩‍💻</h1>
                                <h3 class="text-white"><span>Auto-enable "Do not disturb" while coding or designing.</span></h3>
                                <br>
                                <base-button tag="a" href="https://github.com/utsengar/HeadsDown/releases/download/v1.5/HeadsDown.app.zip" type="primary">
                                Download for macOS</base-button>    
                                <br>
                                <p class="text-white small">v1.5 for macOS 10.12+</p>                            
                            </div>
                            <div class="col-lg-6">
                              <br>
                              <br>
                              <img src="/img/HeadsDown.gif" width="100%">
                              <br>
                              <br>
                              <a href="https://www.producthunt.com/posts/heads-down?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-heads-down" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=205660&theme=light" alt="Heads Down 👩‍💻 - Auto-enable 'Do not disturb' while coding or designing. | Product Hunt Embed" style="width: 250px; height: 54px;" width="250px" height="54px" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
            <section class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="ni ni-check-bold" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Supported Apps</h6>
                                    <p class="description mt-3">Currenly supported apps are: 
                                      <b>VS Code, Sublime Text, XCode, IntelliJ IDEA, Figma, Sketch</b></p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="ni ni-istanbul" type="success" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-success text-uppercase">Works automatically</h6>
                                    <p class="description mt-3"><b>Heads Down</b> detects your application of choice and automatically
                                      enables macOS's "Do Not Disturb" feature.
                                    </p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="ni ni-planet" type="warning" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Free and Open Source</h6>
                                    <p class="description mt-3">Heads Down is freely available and source code
                                       is on <a href="https://github.com/utsengar/HeadsDown">Github</a>. 
                                       Tested on macOS Catalina 10.15</p>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    </div>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>
