<template>
    <header class="header-global">
        <base-nav class="navbar-main" transparent type="" effect="light" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                <img src="/img/brand/white.png">
            </router-link>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <a href="../index.html">
                        <img src="/img/brand/blue.png">
                    </a>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
                 <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-ui-04 d-lg-none"></i>
                        <span class="nav-link-inner--text">Projects</span>
                    </a>
                    <div class="dropdown-menu-inner">
                            <a href="https://chrome.google.com/webstore/detail/stockly-track-stocks-mfs/mmjnmbbgchmodenhejjmjlhjdoalinfd?hl=en"
                               class="media d-flex align-items-center">
                                <div class="">
                                    <img src="/img/stockly.png" width="50px" height="50px">
                                </div>
                                <div class="media-body ml-3">
                                    <h6 class="heading text-primary mb-md-1">Stockly 📈</h6>
                                    <p class="description d-none d-md-inline-block mb-0">A chrome extension to track Stocks, MFs, ETFs and Bonds</p>
                                </div>
                            </a>
                            <a href="http://knowledgebin.org/"
                               class="media d-flex align-items-center">
                                <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                    KB
                                </div>
                                <div class="media-body ml-3">
                                    <h5 class="heading text-warning mb-md-1">Knowledge Bin 📚</h5>
                                    <p class="description d-none d-md-inline-block mb-0">An Open e-Library for Engineering Entrance Exams in India</p>
                                </div>
                            </a>
                            <router-link to="/headsdown" title="Heads Down" class="media d-flex align-items-center">
                                <div class="">
                                    <img src="/img/HDLogo.png" width="40px" height="40px">
                                </div>
                                <div class="media-body ml-3">
                                    <h5 class="heading text-warning mb-md-1">Heads Down 👩‍💻</h5>
                                    <p class="description d-none d-md-inline-block mb-0">Mac App which auto-enables "Do not disturb" when you are coding.</p>
                                </div>
                            </router-link>
                    </div>
                </base-dropdown>
            </ul>
        </base-nav>
    </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  }
};
</script>
<style>
</style>
